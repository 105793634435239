import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Logo_icon } from "../imagepath";
import $ from "jquery";

class SidebarNav extends Component {
  componentDidMount() { }

  render() {
    if (this.props.isAuthenticated) {
      const exclusionArray = [
        "login",
        "register",
        "forgot-password",
        "lock-screen",
        "error-404",
        "error-500",
      ];
      if (
        exclusionArray.indexOf(this.props.location.pathname.split("/")[1]) >= 0
      ) {
        return "";
      }
      const { location } = this.props;
      const pathname = location.pathname.split("/")[1];

      return (
        <>
          {/* Sidebar */}
          <div className="sidebar" id="sidebar">
            <div className="sidebar-logo">
              <Link to="/">
                <img src={Logo_icon} className="img-fluid" alt="" />
              </Link>
            </div>
            <div className="sidebar-inner slimscroll">
              <div id="sidebar-menu" className="sidebar-menu">
                <ul>
                  <li
                    className={`${
                      pathname === "/" || pathname === "" ? "active" : ""
                    }`}
                  >
                    <Link to="/">
                      <i className="fas fa-columns" /> <span>Dashboard</span>
                    </Link>
                  </li>
                  <li className={`${pathname === "profile" ? "active" : ""}`}>
                    <Link to="/profile">
                      <i className="fas fa-columns" /> <span>Profile</span>
                    </Link>
                  </li>
                  <li
                    className={`${
                      pathname === "categories" ||
                      pathname === "add-category" ||
                      pathname === "edit-category"
                        ? "active"
                        : ""
                    }`}
                  >
                    <Link to="/categories">
                      <i className="fas fa-layer-group" />
                      <span>Categories</span>
                    </Link>
                  </li>
                  <li
                    className={`${
                      pathname === "subcategoriess" ? "active" : ""
                    }`}
                  >
                    <Link to="/subcategoriess">
                      <i className="fas fa-layer-group" />
                      <span>Sub-Categories</span>
                    </Link>
                  </li>
                  <li className={`${pathname === "curations" ? "active" : ""}`}>
                    <Link to="/curations">
                      <i className="fas fa-layer-group" />
                      <span>Curations</span>
                    </Link>
                  </li>
                  <li className={`${pathname === "features" ? "active" : ""}`}>
                    <Link to="/features">
                      <i className="fas fa-layer-group" />
                      <span>Product Features</span>
                    </Link>
                  </li>
                  <li className={`${pathname === "tags" ? "active" : ""}`}>
                    <Link to="/tags">
                      <i className="fas fa-layer-group" />
                      <span>Tags</span>
                    </Link>
                  </li>

                  <li
                    className={`${
                      pathname === "newsletter" ||
                      pathname === "newsletter-details"
                        ? "active"
                        : ""
                    }`}
                  >
                    <Link to="/newsletter">
                      <i className="fa fa-newspaper" />
                      <span> Newsletter</span>
                    </Link>
                  </li>

                  <li
                    className={`${
                      pathname === "brands" || pathname === "brand-details"
                        ? "active"
                        : ""
                    }`}
                  >
                    <Link to="/brands">
                      <i className="fas fa-bullhorn" />
                      <span> Brands</span>
                    </Link>
                  </li>
                  <li
                    className={`${
                      pathname === "new-arrival" ||
                      pathname === "new-arrival-details"
                        ? "active"
                        : ""
                    }`}
                  >
                    <Link to="/new-arrival">
                      <i className="fas fa-bullhorn" />
                      <span> New Arrival</span>
                    </Link>
                  </li>
                  <li className={`${pathname === "stories" ? "active" : ""}`}>
                    <Link to="/stories">
                      <i className="fas fa-blog" />
                      <span> Stories</span>
                    </Link>
                  </li>
                  <li className={`${pathname === "cart" ? "active" : ""}`}>
                    <Link to="/cart">
                      <i className="fas fa-blog" />
                      <span> Cart</span>
                    </Link>
                  </li>
                  <li className={`${pathname === "orders" ? "active" : ""}`}>
                    <Link to="/orders">
                      <i className="fas fa-blog" />
                      <span> Orders</span>
                    </Link>
                  </li>
                  <li className={`${pathname === "payments" ? "active" : ""}`}>
                    <Link to="/payments">
                      <i className="fas fa-blog" />
                      <span> Payments</span>
                    </Link>
                  </li>
                  <li className={""}>
                    <a onClick={this.props.logOut}>
                      <i className="fas fa-lock" />
                      <span> Log Out</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Sidebar */}
        </>
      );
    } else {
      return null;
    }
  }
}

export default withRouter(SidebarNav);

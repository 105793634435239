import React, { Component } from "react";
import { Link } from "react-router-dom";

class Loader extends Component {
    render() {
        return (
            <div className="error-box">
                Loading...
            </div>
        );
    }
}

export default Loader;

export {default as Logo_icon} from '../assets/img/logo-icon.png';
export {default as Logo} from '../assets/img/logo.png';
export {default as Favicon} from '../assets/img/favicon.png';
export {default as User} from '../assets/img/user.jpg';
export {default as Img_01} from '../assets/img/img-01.jpg';

export {default as Provider_01} from '../assets/img/provider/provider-01.jpg';
export {default as Provider_02} from '../assets/img/provider/provider-02.jpg';
export {default as Provider_03} from '../assets/img/provider/provider-03.jpg';
export {default as Provider_04} from '../assets/img/provider/provider-04.jpg';
export {default as Provider_05} from '../assets/img/provider/provider-05.jpg';
export {default as Provider_06} from '../assets/img/provider/provider-06.jpg';
export {default as Provider_07} from '../assets/img/provider/provider-07.jpg';
export {default as Provider_08} from '../assets/img/provider/provider-08.jpg';
export {default as Provider_09} from '../assets/img/provider/provider-09.jpg';
export {default as Provider_10} from '../assets/img/provider/provider-10.jpg';

export {default as User_01} from '../assets/img/customer/user-01.jpg';
export {default as User_02} from '../assets/img/customer/user-02.jpg';
export {default as User_03} from '../assets/img/customer/user-03.jpg';
export {default as User_04} from '../assets/img/customer/user-04.jpg';
export {default as User_05} from '../assets/img/customer/user-05.jpg';
export {default as User_06} from '../assets/img/customer/user-06.jpg';
export {default as User_07} from '../assets/img/customer/user-07.jpg';
export {default as User_08} from '../assets/img/customer/user-08.jpg';
export {default as User_09} from '../assets/img/customer/user-09.jpg';
export {default as User_10} from '../assets/img/customer/user-10.jpg';

export {default as Category_01} from '../assets/img/category/category-01.jpg';
export {default as Category_02} from '../assets/img/category/category-02.jpg';
export {default as Category_03} from '../assets/img/category/category-03.jpg';
export {default as Category_04} from '../assets/img/category/category-04.jpg';
export {default as Category_05} from '../assets/img/category/category-05.jpg';
export {default as Category_06} from '../assets/img/category/category-06.jpg';
export {default as Category_07} from '../assets/img/category/category-07.jpg';
export {default as Category_08} from '../assets/img/category/category-08.jpg';
export {default as Category_09} from '../assets/img/category/category-09.jpg';

export {default as Sub_category_01} from '../assets/img/sub-category/sub-category-01.jpg';
export {default as Sub_category_02} from '../assets/img/sub-category/sub-category-02.jpg';
export {default as Sub_category_03} from '../assets/img/sub-category/sub-category-03.jpg';
export {default as Sub_category_04} from '../assets/img/sub-category/sub-category-04.jpg';
export {default as Sub_category_05} from '../assets/img/sub-category/sub-category-05.jpg';
export {default as Sub_category_06} from '../assets/img/sub-category/sub-category-06.jpg';
export {default as Sub_category_07} from '../assets/img/sub-category/sub-category-07.jpg';
export {default as Sub_category_08} from '../assets/img/sub-category/sub-category-08.jpg';
export {default as Sub_category_09} from '../assets/img/sub-category/sub-category-09.jpg';
export {default as Sub_category_10} from '../assets/img/sub-category/sub-category-10.jpg';

export {default as Service_01} from '../assets/img/services/service-01.jpg';
export {default as Service_02} from '../assets/img/services/service-02.jpg';
export {default as Service_03} from '../assets/img/services/service-03.jpg';
export {default as Service_04} from '../assets/img/services/service-04.jpg';
export {default as Service_05} from '../assets/img/services/service-05.jpg';
export {default as Service_06} from '../assets/img/services/service-06.jpg';
export {default as Service_07} from '../assets/img/services/service-07.jpg';
export {default as Service_08} from '../assets/img/services/service-08.jpg';
export {default as Service_09} from '../assets/img/services/service-09.jpg';
export {default as Service_10} from '../assets/img/services/service-10.jpg';

export {default as Avatar_02} from '../assets/img/profiles/avatar-02.jpg';
export {default as Avatar_03} from '../assets/img/profiles/avatar-03.jpg';
export {default as Avatar_04} from '../assets/img/profiles/avatar-04.jpg';
export {default as Avatar_05} from '../assets/img/profiles/avatar-05.jpg';
export {default as Avatar_06} from '../assets/img/profiles/avatar-06.jpg';
export {default as Avatar_07} from '../assets/img/profiles/avatar-07.jpg';
export {default as Avatar_08} from '../assets/img/profiles/avatar-08.jpg';
export {default as Avatar_09} from '../assets/img/profiles/avatar-09.jpg';
export {default as Avatar_10} from '../assets/img/profiles/avatar-10.jpg';
export {default as Avatar_11} from '../assets/img/profiles/avatar-11.jpg';
export {default as Avatar_12} from '../assets/img/profiles/avatar-12.jpg';

export {default as Product_01} from '../assets/img/product/product-01.jpg';
